.rules :global .section-title-container .ant-badge {
  margin-left: 6px;
}
.rules :global .section-title-container .ant-badge-count {
  background-color: #777;
  color: #fff;
}
.rules :global .section-title {
  font-size: 16px;
  font-weight: 500;
}
.rules :global .ant-collapse-borderless {
  border: 1px solid #f2f2f2;
}
